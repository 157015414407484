import { Injectable, Injector } from '@angular/core';
import { HttpHeaders, HttpResponse, HttpEvent, HttpParams } from '@angular/common/http';

import * as printJS from 'print-js';
import { saveAs } from 'file-saver';

import { ApplicationApiService } from './application-api.service';
import { ModalPDFViewerService } from 'src/app/shared/components/pdf-viewer/pdf-viewer.service';


function SafelyParseJSON(json) {
  try {
    return JSON.parse(json);
  } catch (e) {
    return;
  }
}

@Injectable()
export class PDFService extends ApplicationApiService {
  constructor(protected injector: Injector, private pdfViewerService: ModalPDFViewerService) {
    super(injector);
  }

  public get(url: string, search?: { [key: string]: any | any[] }, fileName: string = 'relatorio'): Promise<any> {
    const rep_type = (search ? search['rep_type'] : 'pdf') || 'pdf'
    // return this.request('GET', url, { params: this.createHttpParams(search), rep_type }, fileName);
    return this.getReport('GET', url, { params: this.createHttpParams(search), rep_type }, fileName);
  }

  public post(url: string, body: any, fileName: string = 'relatorio'): Promise<any> {
    // return this.request('POST', url, { body: JSON.stringify(body) }, fileName);
    return this.getReport('POST', url, { body: JSON.stringify(body) }, fileName);
  }

  private getReport(method: string, url: string, options: any, fileName: string = 'relatorio'): Promise<any> {
    const rep_type = (options ? options['rep_type'] : 'pdf') || 'pdf'
    const repName = `${fileName}.${rep_type}`;

    // monta container
    const modal = document.createElement('div');
    modal.style['position'] = 'fixed';
    modal.style['z-index'] = '2500';
    modal.style['width'] = '100%';
    modal.style['height'] = '100%';
    modal.style['background-color'] = 'rgba(0, 0, 0, 0.8)';
    modal.style['display'] = 'flex';
    modal.style['justify-content'] = 'center';
    modal.style['align-items'] = 'center';

    // monta texto
    const text = document.createElement('div');
    text.innerHTML = 'Aguarde gerando impressão...';
    text.style['color'] = 'white';
    text.style['font-size'] = '1.5rem';
    text.style['flex'] = '1 0 auto';
    text.style['text-align'] = 'center';

    // Coloca itens na tela
    modal.appendChild(text);
    document.body.appendChild(modal);

    options['responseType'] = 'blob';
    options['headers'] = new HttpHeaders({
      'Accept': `application/${rep_type}, application/json`,
      'content-type': method === 'POST' ? 'application/json' : `application/${rep_type}`
    });

    return new Promise<any>((resolve, reject) => {
      return this.http.request(method, url, options).pipe()
        .subscribe((resp) => {
          try {
            document.body.removeChild(modal);

            // const ua = navigator.userAgent.toLowerCase();
            // const isAndroid = ua.indexOf('mobile') > -1;

            // const blobURL = URL.createObjectURL(blob);

            // console.log(resp);

            // var reader = new FileReader();
            // reader.readAsDataURL(resp.body);
            // reader.onloadend = function() {
            //   var base64data = reader.result;
            //   console.log(base64data);
            // }

            const blobObj = new Blob([resp], {type: 'application/pdf'});

            if (rep_type !== 'pdf') {
              const blobURL = URL.createObjectURL(blobObj);
              saveAs(blobURL, repName);
            } else {
              this.pdfViewerService.show({blob: blobObj, title: repName})
            }

            // if (isAndroid || rep_type !== 'pdf') {
            //   saveAs(blobURL, repName);
            // } else {
            //   printJS({ printable: blobURL, type: 'pdf', showModal: true, documentTitle: repName });
            // }

            resolve({ ok: true });
          } catch (e) {
            console.log('catch', e);
            resolve({ ok: false });
          }
        }, async (err) => {
          console.log('err', (await err.error.text()));
          document.body.removeChild(modal);
          reject({ ok: false, error: SafelyParseJSON(await err.error.text()) });

          const fr = new FileReader();
          // Ao terminar de ler o retorno, devolve o erro
          fr.onload = () => reject({ ok: false, error: SafelyParseJSON(fr.result) });
          fr.readAsText(err.error);
        });
    });
  }

  private request(method: string, url: string, options: any, fileName: string = 'relatorio'): Promise<any> {
    const rep_type = (options ? options['rep_type'] : 'pdf') || 'pdf'
    const repName = `${fileName}.${rep_type}`;

    // monta container
    const modal = document.createElement('div');
    modal.style['position'] = 'fixed';
    modal.style['z-index'] = '2500';
    modal.style['width'] = '100%';
    modal.style['height'] = '100%';
    modal.style['background-color'] = 'rgba(0, 0, 0, 0.8)';
    modal.style['display'] = 'flex';
    modal.style['justify-content'] = 'center';
    modal.style['align-items'] = 'center';

    // monta texto
    const text = document.createElement('div');
    text.innerHTML = 'Aguarde gerando impressão...';
    text.style['color'] = 'white';
    text.style['font-size'] = '1.5rem';
    text.style['flex'] = '1 0 auto';
    text.style['text-align'] = 'center';

    // Coloca itens na tela
    modal.appendChild(text);
    document.body.appendChild(modal);

    options['responseType'] = 'Blob';
    options['headers'] = new HttpHeaders({
      'Accept': `application/${rep_type}, application/json`,
      'content-type': method === 'POST' ? 'application/json' : `application/${rep_type}`
    });

    // TODO: Verificar retorno Blob
    return new Promise<any>((resolve, reject) => {
      return this.http.request<HttpResponse<Blob>>(method, url, options).pipe()
        .subscribe((blob: HttpEvent<HttpResponse<Blob>>) => {
          document.body.removeChild(modal);

          try {
            const ua = navigator.userAgent.toLowerCase();
            const isAndroid = ua.indexOf('mobile') > -1;

            const blobURL = URL.createObjectURL(blob);

            if (isAndroid || rep_type !== 'pdf') {
              saveAs(blobURL, repName);
            } else {
              printJS({ printable: blobURL, type: 'pdf', showModal: true, documentTitle: repName });
            }

            resolve({ ok: true });
          } catch (e) {
            console.log('catch', e);
            resolve({ ok: false });
          }
        }, async (err) => {
          console.log('err', (await err.error.text()));
          document.body.removeChild(modal);
          reject({ ok: false, error: SafelyParseJSON(await err.error.text()) });

          const fr = new FileReader();
          // Ao terminar de ler o retorno, devolve o erro
          fr.onload = () => reject({ ok: false, error: SafelyParseJSON(fr.result) });
          fr.readAsText(err.error);
        });
    });
  }
}
